import React from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { drawerWidth, drawerWidthCollapsed } from "../constants";
import { useTranslations } from "next-intl";
import useUserRoles from "../../hooks/useUserRoles";
import { useRouter } from "next/router";
import { useStoreState } from "../../store/hooks";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { labelForRoute } from "../../lib/util";
import { LanguageSelect } from "./LanguageSelect";
import { TenantSelect } from "./TenantSelect";
import ElevationScroll from "../common/ElevationScroll";
import { NotificationsMenu } from "components/header/NotificationsMenu";
import Box from "@mui/material/Box";
import { AccountMenu } from "components/header/AccountMenu";
import { ROLES } from "lib/constants";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => {
  return {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "transparent",
    marginLeft: `calc(${drawerWidthCollapsed}px)`,
    width: `calc(100% - ${drawerWidthCollapsed}px)`,
    height: 48,
    minHeight: 48,
    color: "unset",
    "& .MuiToolbar-root": {
      height: 48,
      minHeight: 48,
    },
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "rgb(224, 224, 224)",
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  };
});

export const HeaderAppBar = (props: any) => {
  const t = useTranslations();
  const roles = useUserRoles();
  const router = useRouter();
  const { query } = router;
  const viewingSenderId = Array.isArray(query?.id) ? query?.id[0] : query?.id;
  const design_access_only = roles?.some((r) =>
    [ROLES.PAGE.DIALOGUE_EDITOR_DESIGN].includes(r)
  );
  const drawerState = useStoreState((state) => state.drawerState);
  return (
    <ElevationScroll>
      <StyledAppBar
        open={drawerState}
        elevation={1}
        sx={{
          backgroundColor: "white",
          ...(props.noShadow && {
            boxShadow: "none",
          }),
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1, color: "unset" }}>
            {labelForRoute(router.route, t)}
            {props.countLabel !== undefined ? ` (${props.countLabel})` : ""}
          </Typography>
          {props.additionalHeader}
          <LanguageSelect
            onLanguageChange={props.onLanguageChange}
            redirectRouterArgs={props.redirectRouterArgs}
          />
          {roles?.includes(ROLES.ADMIN) && (
            <TenantSelect
              sx={{ marginLeft: 1 }}
              redirectRouterArgs={props.redirectRouterArgs}
            />
          )}
          {!design_access_only && (
            <Box sx={{ ml: 1 }}>
              <NotificationsMenu viewingSenderId={viewingSenderId} />
            </Box>
          )}

          <Box sx={{}}>
            <AccountMenu />
          </Box>
        </Toolbar>
      </StyledAppBar>
    </ElevationScroll>
  );
};
